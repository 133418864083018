@use '@angular/material' as mat;

// Variables
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;

@import '@angular/material/theming';
@import '../../app/backend-hotels/layout-hotels/layout-theme.scss';
@import '../../app/authentication/authentication-theme';
@import '../../assets/styles/generic-classes.scss';
@import '../../app/shared/common-components/menu-list-item/menu-list-item-theme.scss';
@import '../../app/backend-hotels/contract/add-contract/add-contract-theme.scss';
@import '../../app/backend-hotels/contract/add-new-contract/add-new-contract-theme.scss';
@import '../../app/backend-hotels/contract/add-initial-contract/add-initial-contract-theme.scss';
@import '../../app/backend-hotels/contract/edit-contract/edit-contract-theme.scss';
@import '../../app/backend-hotels/contract/edit-my-contract/edit-my-contract-theme.scss';
@import '../../app/backend-hotels/add-new-hotel/add-new-hotel-theme.scss';

@include mat.core();


//Payment Status
$requested-color: mat.get-color-from-palette(mat.define-palette(mat.$amber-palette, 600)); // Amber600
$pending-color: mat.get-color-from-palette(mat.define-palette(mat.$amber-palette, 600)); // Amber600
$confirmed-color: mat.get-color-from-palette(mat.define-palette(mat.$blue-palette, 500)); // Blue500;
$processing-payment-color: mat.get-color-from-palette(mat.define-palette(mat.$green-palette, 200)); // Green200;
$payed-color: mat.get-color-from-palette(mat.define-palette(mat.$green-palette, 700)); // Green700;
$processing-refund-color: mat.get-color-from-palette(mat.define-palette(mat.$brown-palette, 200)); // Brown200;
$refunded-color: mat.get-color-from-palette(mat.define-palette(mat.$brown-palette, 500)); // Brown500;
$cancelled-color: mat.get-color-from-palette(mat.define-palette(mat.$red-palette, 500)); // Red500;
$processing-cancelled-color: mat.get-color-from-palette(mat.define-palette(mat.$red-palette, 200)); // Red200;
$expired-color: mat.get-color-from-palette(mat.define-palette(mat.$gray-palette, 700)); // Gray700;
$consumed-color: mat.get-color-from-palette(mat.define-palette(mat.$teal-palette, 700)); // Teal700;
$modified-color: mat.get-color-from-palette(mat.define-palette(mat.$blue-gray-palette, 500)); // BlueGray500;
$processing-modification-color: mat.get-color-from-palette(mat.define-palette(mat.$blue-gray-palette, 200)); // BlueGray200;

$custom-typography: mat.define-typography-config(
    $font-family: Poppins
);

/*Material Theme Colors*/
$primary: mat.define-palette(mat.$blue-palette, 900);
$accent: mat.define-palette(mat.$amber-palette, 500);
$warn: mat.define-palette(mat.$red-palette, 500);

$app-light-theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn: $warn,
    ),
    typography: $custom-typography
));

$background: map-get($app-light-theme, background);
$foreground: map-get($app-light-theme, foreground);

@include mat.all-component-themes($app-light-theme);

/*Theme Colors*/
$main-color: mat.get-color-from-palette($primary, 900);
$main-color-100: mat.get-color-from-palette($primary, 100);
$main-color-500: mat.get-color-from-palette($primary, 500);
$main-color-700: mat.get-color-from-palette($primary, 700);
$accent-500: mat.get-color-from-palette($accent, 500);
$main-color-300: mat.get-color-from-palette($primary, 300);

$warn-color: mat.get-color-from-palette($warn, 500);
$warn-color-100: mat.get-color-from-palette($warn, 100);
$warn-color-50: mat.get-color-from-palette($warn, 50);

@include layout-theme($main-color, $main-color-100);
@include menu-list-item-theme($main-color);

$main-color-contrast: mat.get-contrast-color-from-palette($primary, 500);

$sidebar: #ffffff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;
$sidebar-hover-icons: teal;

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

/*Material Colors*/
$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

$red-100: #ffebee;
$red-200: #ffcdd2;
$red-300: #e57373;
$red-400: #ef5350;
$red-500: #f44336;
$red-600: #e53935;
$red-700: #d32f2f;
$red-800: #c62828;
$red-900: #b71c1c;

$amber-100: #ffecb3;
$amber-200: #ffe082;
$amber-300: #ffd54f;
$amber-400: #ffca28;
$amber-500: #ffc107;
$amber-600: #ffb300;
$amber-700: #ffa000;
$amber-800: #ff8f00;
$amber-900: #ff6f00;

$teal-100: #b2dfdb;
$teal-200: #80cbc4;
$teal-300: #4db6ac;
$teal-400: #26a69a;
$teal-500: #009688;
$teal-600: #00897b;
$teal-700: #00796b;
$teal-800: #00695c;
$teal-900: #004d40;

$blue-100: #bbdefb;
$blue-500: #2196f3;
$blue-900: #0d47a1;

$green-300: mat.get-color-from-palette(mat.define-palette(mat.$green-palette, 300));
$green-500: mat.get-color-from-palette(mat.define-palette(mat.$green-palette, 500));
$green-700: mat.get-color-from-palette(mat.define-palette(mat.$green-palette, 700));

.main-primary {
  color: $main-color !important;
}

.bg-main-primary {
  background-color: $main-color !important;
}

.main-primary-700 {
  color: $main-color-700 !important;
}

.bg-main-primary-700 {
  background-color: $main-color-700 !important;
}

.main-primary-100 {
  color: $main-color-100 !important;
}

.bg-main-primary-500 {
  background-color: $main-color-500 !important;
}

.background-color-md-primary-guajiritos-500 {
    background-color: #2a2a3a;
}

.main-primary-500 {
  color: $main-color-500 !important;
}

.bg-main-primary-100 {
  background-color: $main-color-100 !important;
}

.grey-700 {
  color: $grey-700 !important;
}

.grey-800 {
  color: $grey-800 !important;
}

.white {
  color: #ffffff !important;
}

.grey-900 {
  color: $grey-900 !important;
}

.bg-grey-700 {
  background-color: $grey-700 !important;
}

.grey-500 {
  color: $grey-500 !important;
}

.bg-grey-500 {
  background-color: $grey-500 !important;
}

/*grey*/
.color-grey-100 {
  color: $grey-100;
}

.color-grey-200 {
  color: $grey-200;
}

.color-grey-300 {
  color: $grey-300;
}

.color-grey-400 {
  color: $grey-400;
}

.color-grey-500 {
  color: $grey-500;
}

.color-grey-600 {
  color: $grey-600;
}

.color-grey-700 {
  color: $grey-700;
}

.color-grey-800 {
  color: $grey-800;
}

.color-grey-900 {
  color: $grey-900;
}

/*red*/
.color-red-100 {
  color: $red-100;
}

.color-red-200 {
  color: $red-200;
}

.color-red-300 {
  color: $red-300;
}

.color-red-400 {
  color: $red-400;
}

.color-red-500 {
  color: $red-500;
}

.color-red-600 {
  color: $red-600;
}

.color-red-700 {
  color: $red-700;
}

.color-red-800 {
  color: $red-800;
}

.color-red-900 {
  color: $red-900;
}

/*teal*/
.color-teal-100 {
  color: $teal-100;
}

.color-teal-200 {
  color: $teal-200;
}

.color-teal-300 {
  color: $teal-300;
}

.color-teal-400 {
  color: $teal-400;
}

.color-teal-500 {
  color: $teal-500;
}

.color-teal-600 {
  color: $teal-600;
}

.color-teal-700 {
  color: $teal-700;
}

.color-teal-800 {
  color: $teal-800;
}

.color-teal-900 {
  color: $teal-900;
}

/*amber*/
.color-amber-100 {
  color: $amber-100;
}

.color-amber-200 {
  color: $amber-200;
}

.color-amber-300 {
  color: $amber-300;
}

.color-amber-400 {
  color: $amber-400;
}

.color-amber-500 {
  color: $amber-500;
}

.color-amber-600 {
  color: $amber-600;
}

.color-amber-700 {
  color: $amber-700;
}

.color-amber-800 {
  color: $amber-800;
}

.color-amber-900 {
  color: $amber-900;
}

.color-white {
  color: white !important;
}

.background-color-white {
  background-color: $white;
}

.background-color-grey-50 {
  background-color: $grey-50 !important;
}

.background-color-grey-100 {
  background-color: $grey-100;
}

.background-color-grey-200 {
  background-color: $grey-200;
}

.background-color-grey-300 {
  background-color: $grey-300;
}

.background-color-grey-400 {
  background-color: $grey-400;
}

.background-color-grey-500 {
  background-color: $grey-500;
}

.background-color-grey-600 {
  background-color: $grey-600;
}

.background-color-grey-700 {
  background-color: $grey-700;
}

.background-color-grey-800 {
  background-color: $grey-800;
}

.background-color-grey-900 {
  background-color: $grey-900;
}

/*red*/
.background-color-red-100 {
  background-color: $red-100;
}

.background-color-red-200 {
  background-color: $red-200;
}

.background-color-red-300 {
  background-color: $red-300;
}

.background-color-red-400 {
  background-color: $red-400;
}

.background-color-red-500 {
  background-color: $red-500;
}

.background-color-red-600 {
  background-color: $red-600;
}

.background-color-red-700 {
  background-color: $red-700;
}

.background-color-red-800 {
  background-color: $red-800;
}

.background-color-red-900 {
  background-color: $red-900;
}

/*teal*/
.background-color-teal-100 {
  background-color: $teal-100;
}

.background-color-teal-200 {
  background-color: $teal-200;
}

.background-color-teal-300 {
  background-color: $teal-300;
}

.background-color-teal-400 {
  background-color: $teal-400;
}

.background-color-teal-500 {
  background-color: $teal-500;
}

.background-color-teal-600 {
  background-color: $teal-600;
}

.background-color-teal-700 {
  background-color: $teal-700;
}

.background-color-teal-800 {
  background-color: $teal-800;
}

.background-color-teal-900 {
  background-color: $teal-900;
}

/*amber*/
.background-color-amber-100 {
  background-color: $amber-100;
}

.background-color-amber-200 {
  background-color: $amber-200;
}

.background-color-amber-300 {
  background-color: $amber-300;
}

.background-color-amber-400 {
  background-color: $amber-400;
}

.background-color-amber-500 {
  background-color: $amber-500;
}

.background-color-amber-600 {
  background-color: $amber-600;
}

.background-color-amber-700 {
  background-color: $amber-700;
}

.background-color-amber-800 {
  background-color: $amber-800;
}

.background-color-amber-900 {
  background-color: $amber-900;
}
